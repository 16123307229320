import './App.scss'
import NavBar from './components/NavBar'
import { ParallaxProvider } from 'react-scroll-parallax';
import Title from './components/Title';
import React from 'react'
import NFTContent from './components/NFTContent';
import Roadmap from './components/Roadmap';
import Footer from './components/Footer';
import BaseContent from './components/BaseContent';
import Partners from './components/Partners';

import Deck from './components/Deck'
import Compendium from './components/Compendium';

window.onbeforeunload = function () {
  window.scrollTo(0, 0);
}

export default function App() {
  return (
    <ParallaxProvider>
        <div className="App">
          <NavBar />
          
          
          <Title />
          <NFTContent />
          <Compendium />
          <BaseContent />
          <Roadmap />
          <Partners />
          <Footer />
          
        </div>
    </ParallaxProvider>
  );
}