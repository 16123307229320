import React from 'react'
import './Footer.css'
import DiscordLogo from '../assets/icons/discord-xxl.png'

function Footer() {
  return (
    <div className="FooterContainer">
        <div className="FooterContent">
            <div className="FCTop">
              <div className="PolicyIDs">
                <div className="PIDChild">
                  <span className='PIDHeader'>Runic Dust Policy ID:</span>
                  <span className='PIDText'>
                    1408d4e68d45a9af7f00e2da46630b46e6d9ed0aedcb2185b799b888</span>
                </div>
                <div className="PIDChild">
                  <span className='PIDHeader'>NFT Collection Policy ID:</span>
                  <span className='PIDText'>
                    5d6aa5c975266893fc446f24a094dddc59192638b5da0ec0691ec0ae</span>
                </div>
              </div>
            </div>
            <div className="FCBottom">
              <div className="FCBottomL">
                <span>Copyright © 2022 Runico Studios. All rights reserved.</span>
              </div>
              <div className="FCBottomR">
              <a href='https://discord.gg/84aQ9rGqcA' target="_blank"><img id='DisLogo' src={DiscordLogo} /></a>
              </div>
            </div>
        </div>
    </div>
  )
}

export default Footer