import React, { useRef, useState, useEffect } from 'react'
import { Parallax } from 'react-scroll-parallax'
import './Roadmap.css'
import Tilt from 'react-parallax-tilt'
import LineTo from 'react-lineto'
import Xarrow, {useXarrow, Xwrapper} from 'react-xarrows'
import Draggable from 'react-draggable'

export function jumpToRoadmap() {
    var elem = document.getElementById('idRoadmapContainer')
    let pos = elem.offsetTop
    let jumpVal = pos - 100
    window.scrollTo({
      top: jumpVal,
      left: 0,
      behavior: 'smooth'
    });
  }

const Block = ({box, index}) => {

    const updateXarrow = useXarrow();

    

    const isEven = index % 2 == 0
    const highLow = isEven ? "RMGBlockHigh" : "RMGBlockLow"

    useEffect(() => {
        
        window.addEventListener('scroll', updateXarrow);
    
        return () => {
          window.removeEventListener('scroll', updateXarrow);
        };
      }, []);

    return (
        <div className={"RMGBlock"} id={box.id} onDrag={updateXarrow} onStop={updateXarrow}>
            <span>{box.date}</span>
            <ul>
                {box.points.map((list) => 
                    <li className={'li' + box.id}>{list}</li>
                )}
            </ul>
        </div>
    );
};

function Roadmap() {
    
    
    const boxes = [
        { id: 'box1', date: 'Q2 2022', points: [ 
            "First Gen NFT Launch",
            "Unity Demo Launch",
            "Cardano Wallet API",
            "Social Media Launch",
            "Private Token Mint",
            "Limited Closed Beta"
        ], reference: useRef(null) },
        { id: 'box2', date: 'Q3 2022', points: [
            "Closed Beta Launch",
            "Network Code",
            "Upgradeable Base",
            "Smart Contract Development",
            "Private Token Sale",
            "Sound & Music Development"
        ], reference: useRef(null) },
        { id: 'box3', date: 'Q4 2022', points: [
            "Public Beta Launch",
            "Legendary NFTs Enabled",
            "New NFT Forging Enabled",
            "Full Feature Marketplace"
        ], reference: useRef(null) },
        { id: 'box4', date: 'Q1 2023', points: [
            "Full Public Launch",
            "Competitive Ladder",
            "All Unit Variations Released",
            "Tournaments Enabled"
        ], reference: useRef(null) }
      ];

      const [lines] = useState([
        {
            start: 'box1',
            end: 'box2',
            startAnchor: 'right',
            // endAnchor: 'top',
            color: '#0D0C00',
        },
        {
            start: 'box2',
            end: 'box3',
            startAnchor: 'right',
            // endAnchor: 'bottom',
            color: '#0D0C00',
        },
        {
          start: 'box3',
          end: 'box4',
          startAnchor: 'right',
        //   endAnchor: 'top',
          color: '#0D0C00',
        },
      ]);

  return (
    <Parallax className='RoadmapContainer' id='idRoadmapContainer'
        startScroll={1100}
        endScroll={1600}
        opacity={[0, 1.0]}
        scale={[0.85, 1]}
        translateY={[50, 0]}
    >
        <div className="RoadmapContent">
            <Tilt className="RoadmapTitle"
                tiltEnable={false}
                scale={1.04}
                transitionSpeed={2500}
            >
                <span>RuneFortress Roadmap</span>
            </Tilt>
            <div className="RMGContainer">
                <Xwrapper>
                    {boxes.map((box, i) => (
                        <Tilt className={'RMGBlock' + ((i % 2 == 0) ? 'High' : 'Low')}
                            tiltMaxAngleX={5} tiltMaxAngleY={5}
                            perspective={1000}
                            scale={1.05}
                            transitionSpeed={10000}
                            gyroscope={true}
                        >
                            <Block box={box} key={i} index={i} />
                        </Tilt>
                        
                    ))}
                    {/* LINES REDACTED */}
                    {/* {lines.map((line, i) => (
                        <Xarrow className='lines' key={i} {...line} />
                    ))} */}
                </Xwrapper>    
            </div>
        </div>
    </Parallax>
  )
}

export default Roadmap