import React from 'react'
import { Parallax } from 'react-scroll-parallax'
import './BaseContent.css'

function BaseContent() {
  return (
    <Parallax className="BaseContainer"
      // startScroll={800}
      // endScroll={1200}
      translateY={[-15, -30]}
    >
      <Parallax className="TextContainer"
        startScroll={2400}
        endScroll={2800}
        opacity={[1,0]}
      >
        <Parallax className="TextContent"
          startScroll={0}
          endScroll={2200}
          translateX={[-100,0]}
        >
          
            <span className='BaseText'>Build Your Fortress</span>
          
        </Parallax>
      </Parallax>
    </Parallax>
  )
}

export default BaseContent