import React, {useState, useEffect} from 'react'
import { Parallax } from 'react-scroll-parallax'
import './Compendium.css'
import Tilt from 'react-parallax-tilt';
import UnitData from '../misc/unitStats.json'
import './buttonStyles.css'
import URLS from '../misc/urls'

export function jumpToCompendium() {
  var elem = document.getElementById('idCompendiumContainer')
  let pos = elem.offsetTop
  let jumpVal = pos - 100
  window.scrollTo({
    top: jumpVal,
    left: 0,
    behavior: 'smooth'
  });
}


function Compendium() {

  const fEUnits = UnitData.firstEdition

  const [activeUnit, setActiveUnit] = useState(12)

  const [imageSrc, setImageSrc] = useState(require('../assets/units/ashBlock00001.jpg'))

  function importAll(r) {
    let images = {}
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); })
    return images
  }

  document.addEventListener('click', function handleClick(event) {
    if (event.target.classList.contains('SelectorImg')) {
      const oldImg = document.getElementsByClassName('active-img')[0]

      oldImg.classList.remove('active-img')

      event.target.classList.add('active-img')

    }
  });

  const images = importAll(require.context('../assets/units', false, /\.(png|jpe?g|svg)$/))

  const items = []


  for (const [key, value] of Object.entries(images)) {

    const decodedKey = Object.keys(fEUnits).find(k => fEUnits[k].name === key.replace(/\.[^/.]+$/, ""))
    if (key == 'ashBlock00001.jpg') {
      items.push(<img 
        src={value} 
        key={decodedKey} 
        onClick={() => {
          jumpToCompendium()
          setActiveUnit(decodedKey)
          setImageSrc(require('../assets/units/' + key))
        }} 
        className="SelectorImg active-img"
      />)
    } else {
      items.push(<img 
        src={value}  
        key={decodedKey} 
        onClick={() => {
          jumpToCompendium()
          setActiveUnit(decodedKey)
          setImageSrc(require('../assets/units/' + key))
        }} 
        className="SelectorImg"
      />)
    }  
  }

  function rotateActiveUnitRight() {
    var temp = activeUnit
    console.log('temp: ' + temp)
    console.log('activeUnit: ' + activeUnit)

    if (temp == 17 ) {
      setActiveUnit(0)
      temp = 0
    } else {
      setActiveUnit(+temp + +1)
      temp = +temp + +1
    }
    console.log('newTemp ' + temp)
    console.log('NewActiveUnit: ' + activeUnit)
    const key = fEUnits[temp].name
    console.log(key)

    setImageSrc(require('../assets/units/' + key + '.jpg'))
  }

  function rotateActiveUnitLeft() {
    var temp = activeUnit

    if (temp == 0) {
      setActiveUnit(17)
      temp = 17
    } else {
      setActiveUnit(temp - 1)
      temp -= 1
    }

    const key = fEUnits[temp].name

    setImageSrc(require('../assets/units/' + key + '.jpg'))

  }

  return (
    <Parallax className="CompendiumContainer" id='idCompendiumContainer'
      translateY={[0, -10]}
    >
      <div className='CompendiumContent'>
          <div className="CompendiumTitle"><span>Build Your Team</span></div>
          <div className="SSLineAnimation"></div>
          <div className='CompendiumContentInner'>
            <div className='CmpButton CmpButtonL' onClick={rotateActiveUnitLeft}>{'<'}</div>
            <div className='CmpL'>
              <div className='CmpLT'>
                <div className='CmpLTL'>
                  <Tilt className="CmpLTLUnitTilt"
                    tiltEnable={false}
                    scale={1.02}
                    transitionSpeed={2500}
                    glareEnable={true} 
                    glareMaxOpacity={0.8} 
                    glareColor="white" 
                  >
                    <img className="CmpLTLUnitImg" src={imageSrc} />
                  </Tilt>
                </div>
                <div className='CmpLTR'>
                  <div className='CmpLTLUnitHeader'><span>{fEUnits[activeUnit].name}</span></div>
                  <div className='CmpLTLUnitInfo'>
                    <div className='CmpLTLUnitInfoL'><span>COST:</span></div>
                    <div className='CmpLTLUnitInfoR'><span>{fEUnits[activeUnit].cost}</span></div>
                  </div>
                  <div className='CmpLTLUnitInfo'>
                    <div className='CmpLTLUnitInfoL'><span>HEALTH:</span></div>
                    <div className='CmpLTLUnitInfoR'><span>{fEUnits[activeUnit].health}</span></div>
                  </div>
                  <div className='CmpLTLUnitInfo'>
                    <div className='CmpLTLUnitInfoL'><span>ATTACK:</span></div>
                    <div className='CmpLTLUnitInfoR'><span>{fEUnits[activeUnit].attack}</span></div>
                  </div>
                  <div className='CmpLTLUnitInfo'>
                    <div className='CmpLTLUnitInfoL'><span>DEFENCE:</span></div>
                    <div className='CmpLTLUnitInfoR'><span>{fEUnits[activeUnit].defence}</span></div>
                  </div>
                  <div className='CmpLTLUnitInfo'>
                    <div className='CmpLTLUnitInfoL'><span>HEADPIECE:</span></div>
                    <div className='CmpLTLUnitInfoR'><span>{fEUnits[activeUnit].headPiece}</span></div>
                  </div>
                  <div className='CmpLTLUnitInfo CmpLTLUnitInfoButton'>
                  <a class="fancy" href={URLS.MintingURL} target="_blank">
                    <span class="top-key"></span>
                    <span class="text">Get This NFT</span>
                    <span class="bottom-key-1"></span>
                    <span class="bottom-key-2"></span>
                  </a>
                  </div>
                </div>
              </div>
              <div className='CmpLB'>
                <div className='CmpLBContent'>
                  <div className='CmpLBContentL'>
                    <span>RUNIC TRAIT:</span>
                  </div>
                  <div className='CmpLBContentR'>
                    <span style={{ fontStyle:'italic' }}>{fEUnits[activeUnit].runicTrait.name}</span>
                    <span>{fEUnits[activeUnit].runicTrait.description}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='CmpR'>
              <div className='CmpRContent'>
                {items}
              </div>  
            </div>
            <div className='CmpButton CmpButtonR' onClick={rotateActiveUnitRight}>{'>'}</div>
          </div>
      </div>
    </Parallax>
  )
}

export default Compendium