import React from 'react'
import './Partners.css'
import GeniusXLogo from '../assets/images/GeniusXLogo.svg'
import CardahubLogo from '../assets/images/CardahubLogo.png'
import URLS from '../misc/urls.json'

const sendToCardahub = () => {
    window.open(URLS.MintingURL, '_blank')
  }

const sendToGenuisX = () => {
    window.open(URLS.GeniusXURL, '_blank')
}

function Partners() {
  return (
    <div className="PartnersContainer">
        <div className="PartnersContent">
            <div className="PartnersTitle"><span>Our Partners</span></div>
            <div className="SSLineAnimationPartner"></div>
            <div className="PartnersLogoContent">
                <div className="LogoContainer" onClick={sendToGenuisX}>
                    <img className="LogoChild" src={GeniusXLogo} />
                </div>
                <div className="LogoContainer" onClick={sendToCardahub}>
                    <img className="LogoChild" src={CardahubLogo} />
                </div>
            </div>
        </div>
    </div>
  )
}

export default Partners