import { Parallax } from 'react-scroll-parallax'
import './NFTContent.css'
import { AnimatedBg, Transition } from 'scroll-background';
import React, { useState } from 'react'
import Deck from './Deck.js'
import URLS from '../misc/urls'

import { AwesomeButton } from "react-awesome-button"
import AwesomeButtonStyles from "react-awesome-button/src/styles/styles.scss"

import arrow from '../assets/icons/curved-arrow.png'

export const sendToMintingURL = () => {
  window.open(URLS.MintingURL, '_blank')
}

function NFTContent() {
  return (
    <Parallax className="NFTContainer"
      // startScroll={0}
      // endScroll={900}
      // translateY={[-200, 100]}
    >
      <div className="NFTWrap">
        <div className="CardShowcase">
          <Deck />
          {/* Redacted ClickMe  */}
          {/* <div className="ClickMe"><span>drag me!</span></div>
          <img className="blackArrow" src={arrow} alt="arrow pointing to cards" /> */}
        </div>
        <div className="NFTAdjacent">
          <div className='NFTAdjWrap'>
            <h1 style={{ fontSize: '32px', color: '#23272A'}}>Minting Is Now Live!</h1>
            <p>
              Build your deck with the 18 base units currently availabe - 
                each with their own unique stats and abilities. Only a 
                limited number of each varient will be minted, so get in
                early before they're all gone!<br/><br/>
                -- Exclusively available on Cardahub.io
            </p>
            <button class="button-82-pushable" role="button" onClick={sendToMintingURL} >
              <span class="button-82-shadow"></span>
              <span class="button-82-edge"></span>
              <span class="button-82-front text">
                Build Your Deck
              </span>
            </button>
          </div>
        </div>
      </div>
      
      
      
    </Parallax>
  )
}

export default NFTContent