import React from 'react'
import './NavBar.css'
import Logo from '../assets/images/LogoCondensed_WhiteBorder.png'
import DiscordLogo from '../assets/icons/discord-xxl.png'
import URLS from '../misc/urls'

import { jumpToCompendium } from './Compendium'
import { jumpToRoadmap } from './Roadmap'
import { sendToMintingURL } from './NFTContent'

const sendToGame = () => {
  window.open(URLS.GameURL, '_blank')
}


export const jumpToHome = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
}

const navLi = document.querySelectorAll("ul li")
window.onscroll = () => {
  var current = window.screenTop;

  navLi.forEach((li) => {
    li.classList.remove("active");
  });
  if (current < 500) {
    var tagHome = document.getElementById("")
  }
}

function NavBar() {
  return (
    <div className="NavBar">
      <ul className="NavContent">
          <li>
            <a id="tagHome" className='subHead' onClick={ jumpToHome }>Home</a>
          </li>
          <li>
            <a id="tagNFTs" className='subHead' onClick={ sendToMintingURL }>NFTs</a>
          </li>
          <div id="LineAnimationLeft" className='subHead'></div>
          <li>         
            <a onClick={ jumpToHome }>
                <img id='Logo' src={Logo} />
            </a>
          </li>
          <div id="LineAnimationRight" className='subHead'></div>
          <li>
            <a href='https://discord.gg/84aQ9rGqcA' className='subHead' target="_blank">Discord <img id='DisLogo' src={DiscordLogo} /></a>
          </li>
          <li>
            <a id="tagRoadmap" className='subHead' onClick={ sendToGame }>Play</a>
          </li>
          
      </ul>
      
    </div>
    
  )
}

export default NavBar