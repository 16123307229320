import React from 'react'
import { useParallax } from 'react-scroll-parallax';
import Tilt from 'react-parallax-tilt';
import LogoFull from '../assets/images/LogoFull_WhiteBorder-min.png'
import RibbonLabel from '../assets/icons/label_ribbon_01.png'
import { Parallax } from 'react-scroll-parallax';
import './Title.scss';
import { jumpToCompendium } from './Compendium';

function bannerClicked(e) {
  e.preventDefault();
  console.log("Button Clicked!")
  jumpToCompendium()
  // document.getElementById('nftContainerJump').scrollIntoView()
}


const Title = () => {

  return (

    <Parallax className="Layer1"
      startScroll={0}
      endScroll={300}
      opacity={[1.0, 0]}
      scale={[1.0, 0.85]}
      translateY={[0, -20]}
    >
      <Tilt 
        className="tilt-img"
        tiltMaxAngleX={5} tiltMaxAngleY={5}
        perspective={1000}
        scale={1.02}
        transitionSpeed={10000}
        style={{ zIndex: '5' }}
      >
        <img className="MainLogo" src={LogoFull}  />
      </Tilt>

      <div className="SubtitleContainer">
        <h1 style={{ color: 'white' }}>Cardano-Inspired Blockchain Game</h1>
      </div>

      {/* Banner Redacted */}

      {/* <Tilt
        className='parallax-effect'
        perspective={200}
        scale={1.1}
        tiltMaxAngleX={1} tiltMaxAngleY={1}
        onClick={bannerClicked}
      >
        <div className="inner-element" onClick={bannerClicked}>
          <div className="Scroll" />
          <div className="BannerText" >Explore The Collection</div>
          <div className="Scroll" />
        </div>
      </Tilt> */}
    </Parallax> 
  )
}

export default Title